import base from "../config/base.json";
import axios from "axios"
import Cookies from 'js-cookie';

var _settings: any = null;
const getSettings = () => {
  if (_settings !== null) {
    return _settings
  }
  _settings = new Settings()
  console.log(_settings, "_settings")
  return _settings
}

class Settings {
  API_HOST: string;
  WS_HOST: string;
  STATIC_HOST: string;
  TAGOPTIONS_URL: string;
  WEB_RTC: string;
  APP_NAME: string;
  USERNAME_PASSWORD_LOGIN: boolean;
  EMAIL_LOGIN: boolean;
  PENTA_ACC_FACEBOOK_LOGIN: boolean;
  PENTA_ACC_LINE_LOGIN: boolean;
  PENTA_ACC_EMAIL_LOGIN: boolean;
  RECAPTCHA_KEY: string;
  APPOINTMENT_CHAT_FUNCTION: boolean;
  BOOKING_FUNCTION: boolean;
  DASHBOARD: boolean;
  FORM_ANALYTICS: boolean;
  WEB_PUSH_PUBLIC_KEY: string;
  LATEST_ENCOUNTER_TOP: string;
  HIDE_QUEUE_FILTER_DATE: string;
  USE_PAYMENT_STATUS_LABEL: string;
  OPEN_FORM_ENCOUNTER: string;
  IGNORE_CAN_CHAT: string;
  HIDE_CALL_BUTTON: string;
  QUEUE_API_V2: boolean;
  QUEUE_OA_DASHBOARD_APPOINTMENT: boolean;
  HIDE_QUEUE: string;
  HIDE_SIDEBAR: string;
  HIDE_DIVISION: string;
  HIDE_NOTE: string;
  HIDE_CLASSIFY: string;
  COMPANY: string;
  PROJECT: string;
  SETTINGS: boolean;
  STUDIO: boolean;
  MANAGE_SCHEDULE: boolean;
  CLASSIFY_CHAT: boolean;
  HIDE_CLASSIFY_SUBMENU_CHAT: boolean;
  HIDE_CLASSIFY_SUBMENU_ASSESSMENT: boolean;
  HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE: boolean;
  HIDE_CLASSIFY_SUBMENU_NURSE_NOTE: boolean;
  HIDE_CLASSIFY_SUBMENU_VITAL_SIGN: boolean;
  HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE: boolean;
  HIDE_CLASSIFY_SUBMENU_PROFILE: boolean;
  HIDE_CLASSIFY_BUTTON_NEW_PATIENT: boolean;
  ALLOW_CREATE_VACCINE_APPOINTMENT: boolean;
  CLASSIFY_CHAT_FORM_HOST: string;
  OFFICIAL_ACCOUNT_HOST: string;
  PDF_PRINT_DIAGFORM_ID: number[];
  SHOW_BIND_HN: boolean;
  GCS_BUCKET_NAME: string;
  AIRFLOW_CHAT: boolean;
  USER_LIST_CHAT: boolean;
  UNSIGNED_FILTER: boolean;
  ENCOUNTER_CHAT: boolean;
  COPY_LINK: boolean;
  CREATE_OFFICIAL_ACCOUNT: boolean;
  COUNTER_VERIFY_IDENTITY: boolean;
  HIDE_ASSESSMENT: boolean;
  PATIENT_PANEL_INVOICE: boolean;
  CALL_RECEIVED_RECLASSIFY: boolean;
  CONFIG_UPDATE_SUCCESS: boolean;
  HIDE_PATIENT_PROFILE_DOWNLOAD: boolean;
  HIDE_CONSENT_FORM_DOWNLOAD: boolean;
  SHOW_APP_TRIAGE_FILTER_CHAT: boolean;
  DOMAIN: string;
  FAVOR: any;
  ENABLE_LAUNCH_PAGE:boolean;
  ENABLE_MAINTAIN:boolean;

  constructor(domain = window.location.hostname) {
    this.DOMAIN = "default"

    const favor = this.selectDomain(domain);

    this.FAVOR = favor;

    // url of api server
    this.API_HOST = favor.API_HOST || window.location.origin;
    // websocket server host name
    this.WS_HOST = favor.WS_HOST || window.location.hostname;
    // static host for api such as TRIAGE_LEVEL_CLASSIFY
    this.STATIC_HOST = favor.STATIC_HOST || 'https://admin.ishealth.app';

    // tagOptions 
    this.TAGOPTIONS_URL = favor.TAGOPTIONS_URL || '';

    // host of web rtc server
    this.WEB_RTC = favor.WEB_RTC || 'https://webrtc.drjaysayhi.com';

    this.APP_NAME = favor.APP_NAME || 'ishealth';

    this.USERNAME_PASSWORD_LOGIN = favor.USERNAME_PASSWORD_LOGIN === undefined ?
      true : favor.USERNAME_PASSWORD_LOGIN;

    this.EMAIL_LOGIN = favor.EMAIL_LOGIN === undefined ? false : favor.EMAIL_LOGIN

    // Enable accounts.thevc authentication options
    this.PENTA_ACC_FACEBOOK_LOGIN = favor.PENTA_ACC_FACEBOOK_LOGIN === undefined ?
      true : favor.PENTA_ACC_FACEBOOK_LOGIN;
    this.PENTA_ACC_LINE_LOGIN = favor.PENTA_ACC_LINE_LOGIN === undefined ?
      true : favor.PENTA_ACC_LINE_LOGIN;
    this.PENTA_ACC_EMAIL_LOGIN = favor.PENTA_ACC_EMAIL_LOGIN === undefined ?
      true : favor.PENTA_ACC_EMAIL_LOGIN;

    this.RECAPTCHA_KEY = favor.RECAPTCHA_KEY === undefined ?
      "6LdvmfUUAAAAAIb2DBfgd_xeN-xk4b6qq_3aR-UH" : favor.RECAPTCHA_KEY

    // Tab
    this.APPOINTMENT_CHAT_FUNCTION = false

    // Booking function
    this.BOOKING_FUNCTION = false

    // Dashboard
    this.DASHBOARD = false;

    this.FORM_ANALYTICS = false;

    this.SETTINGS = false;

    this.STUDIO = false;

    this.CLASSIFY_CHAT = false;

    this.MANAGE_SCHEDULE = false;

    this.AIRFLOW_CHAT = false;

    this.SHOW_BIND_HN = false;

    this.USER_LIST_CHAT = false;

    this.ENCOUNTER_CHAT = true;

    this.COPY_LINK = false;

    this.CREATE_OFFICIAL_ACCOUNT = false;

    this.COUNTER_VERIFY_IDENTITY = false;

    this.CONFIG_UPDATE_SUCCESS = false;

    this.CALL_RECEIVED_RECLASSIFY = favor.CALL_RECEIVED_RECLASSIFY === undefined ?
      false : favor.CALL_RECEIVED_RECLASSIFY;

    this.PATIENT_PANEL_INVOICE = favor.PATIENT_PANEL_INVOICE === undefined ?
      false : favor.PATIENT_PANEL_INVOICE;

    this.HIDE_ASSESSMENT = favor.HIDE_ASSESSMENT === undefined ?
      false : favor.HIDE_ASSESSMENT;

    this.UNSIGNED_FILTER = favor.UNSIGNED_FILTER === undefined ?
      false : favor.UNSIGNED_FILTER;

    this.HIDE_CLASSIFY_SUBMENU_CHAT = favor.HIDE_CLASSIFY_SUBMENU_CHAT === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_CHAT;

    this.HIDE_CLASSIFY_SUBMENU_ASSESSMENT = favor.HIDE_CLASSIFY_SUBMENU_ASSESSMENT === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_ASSESSMENT;

    this.HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE = favor.HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE;

    this.HIDE_CLASSIFY_SUBMENU_NURSE_NOTE = favor.HIDE_CLASSIFY_SUBMENU_NURSE_NOTE === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_NURSE_NOTE;

    this.HIDE_CLASSIFY_SUBMENU_VITAL_SIGN = favor.HIDE_CLASSIFY_SUBMENU_VITAL_SIGN === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_VITAL_SIGN;

    this.HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE = favor.HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE;

    this.HIDE_CLASSIFY_SUBMENU_PROFILE = favor.HIDE_CLASSIFY_SUBMENU_PROFILE === undefined ?
      false : favor.HIDE_CLASSIFY_SUBMENU_PROFILE;

    this.HIDE_CLASSIFY_BUTTON_NEW_PATIENT = favor.HIDE_CLASSIFY_BUTTON_NEW_PATIENT === undefined ?
      false : favor.HIDE_CLASSIFY_BUTTON_NEW_PATIENT;

    this.HIDE_PATIENT_PROFILE_DOWNLOAD = favor.HIDE_PATIENT_PROFILE_DOWNLOAD === undefined ?
      false : favor.HIDE_PATIENT_PROFILE_DOWNLOAD;

    this.ALLOW_CREATE_VACCINE_APPOINTMENT = favor.ALLOW_CREATE_VACCINE_APPOINTMENT === undefined ?
      false : favor.ALLOW_CREATE_VACCINE_APPOINTMENT;

    this.HIDE_CONSENT_FORM_DOWNLOAD = favor.HIDE_CONSENT_FORM_DOWNLOAD === undefined ?
      false : favor.HIDE_CONSENT_FORM_DOWNLOAD;

    this.SHOW_APP_TRIAGE_FILTER_CHAT = favor.SHOW_APP_TRIAGE_FILTER_CHAT === undefined ?
      false : favor.SHOW_APP_TRIAGE_FILTER_CHAT;

    this.CLASSIFY_CHAT_FORM_HOST = favor.CLASSIFY_CHAT_FORM_HOST || "https://ishealth.app";

    this.OFFICIAL_ACCOUNT_HOST = favor.OFFICIAL_ACCOUNT_HOST || "https://ishealth.app";

    this.GCS_BUCKET_NAME = favor.GCS_BUCKET_NAME || "mybplus-waiting-list-staging";

    this.PDF_PRINT_DIAGFORM_ID = favor.PDF_PRINT_DIAGFORM_ID === undefined ?
      [] : favor.PDF_PRINT_DIAGFORM_ID;

    // Webpush
    this.WEB_PUSH_PUBLIC_KEY = favor.WEB_PUSH_PUBLIC_KEY ||
      "BDKyMQyvGoPQ5LvRPBmQGec1_geS441vKvkdYhaNM4WDfd0a403b0BTunKNBST_-mcaR5at3B8FYL-N4xo4x9a0";

    this.LATEST_ENCOUNTER_TOP = favor.LATEST_ENCOUNTER_TOP === undefined ?
      false : favor.LATEST_ENCOUNTER_TOP;

    this.HIDE_QUEUE_FILTER_DATE = favor.HIDE_QUEUE_FILTER_DATE === undefined ? false : favor.HIDE_QUEUE_FILTER_DATE;

    this.USE_PAYMENT_STATUS_LABEL = favor.USE_PAYMENT_STATUS_LABEL === undefined ? false : favor.USE_PAYMENT_STATUS_LABEL;

    this.OPEN_FORM_ENCOUNTER = favor.OPEN_FORM_ENCOUNTER === undefined ? true : favor.OPEN_FORM_ENCOUNTER

    this.IGNORE_CAN_CHAT = favor.IGNORE_CAN_CHAT === undefined ? false : favor.IGNORE_CAN_CHAT

    this.HIDE_CALL_BUTTON = favor.HIDE_CALL_BUTTON === undefined ? false : favor.HIDE_CALL_BUTTON

    // api V2 for penguin
    this.QUEUE_API_V2 = favor.QUEUE_API_V2 === undefined ? false : favor.QUEUE_API_V2

    this.QUEUE_OA_DASHBOARD_APPOINTMENT = favor.QUEUE_OA_DASHBOARD_APPOINTMENT === undefined ? false : favor.QUEUE_OA_DASHBOARD_APPOINTMENT

    // ---------------------------------- for CNMI telemed ----------------------------------
    this.HIDE_QUEUE = favor.HIDE_QUEUE === undefined ? false : favor.HIDE_QUEUE

    this.HIDE_SIDEBAR = favor.HIDE_SIDEBAR === undefined ? false : favor.HIDE_SIDEBAR

    this.HIDE_DIVISION = favor.HIDE_DIVISION === undefined ? false : favor.HIDE_DIVISION

    this.HIDE_NOTE = favor.HIDE_NOTE === undefined ? false : favor.HIDE_NOTE

    this.HIDE_CLASSIFY = favor.HIDE_CLASSIFY === undefined ? false : favor.HIDE_CLASSIFY

    // --------------------------------------------------------------------------------------

    this.COMPANY = favor.COMPANY === undefined ? "" : favor.COMPANY

    this.PROJECT = favor.PROJECT === undefined ? "" : favor.PROJECT
    
    this.ENABLE_LAUNCH_PAGE = favor.ENABLE_LAUNCH_PAGE === undefined ? false : favor.PROJECT

    this.ENABLE_MAINTAIN = favor.ENABLE_MAINTAIN === undefined ? false : favor.ENABLE_MAINTAIN

    this.updateConfig()
  }

  selectDomain(domain: string) {
    const findDomain = Object.keys(base).find((key: string) => new RegExp(`^${key}$`).test(domain))

    if (findDomain) {
      this.DOMAIN = findDomain

      return (base as { [key: string]: any })[findDomain]
    } else if (!('default' in base)) {
      throw `cannot find "${domain}" or "default" in settings`
    }
    return base.default
  }

  updateConfig() {

    axios.get(`${this.API_HOST}/users/apis/screen/`, {
      headers: { 'Authorization': `Token ${Cookies.get("apiToken")}` }
    }).then((res) => {
      const findItem = (res.data as any)?.items?.find((item: any) => JSON.parse(item.extra || '{}')[this.DOMAIN])
      const extra: any = JSON.parse(findItem?.extra || '{}')[this.DOMAIN] || {}
      for (const key in this) {
        if (this.FAVOR[key] !== undefined || extra[key] !== undefined) {
          this[key] = extra[key] ?? this.FAVOR[key]
        }
      }
      this.CONFIG_UPDATE_SUCCESS = true
    }).catch((error) => {
      for (const key in this) {
        if (this.FAVOR[key] !== undefined) {
          this[key] = this.FAVOR[key]
        }
      }
      this.CONFIG_UPDATE_SUCCESS = true
    });
  }
}

// export default new Settings();
export default getSettings();