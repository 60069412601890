import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dimmer } from "semantic-ui-react";
import CONFIG from "../config/config";

export const Main = (props: any) => {
  const history = useHistory();

  const [iframeHeight, setIframeHeight] = useState(480);
  const [iframeWidth, setIframeWidth] = useState(640);
  const [visible, setVisible] = useState(true);
  const [videoCallStartPip, setVideoCallStartPip] = useState(false);

  useEffect(() => {
    setVisible(props.openVideoCallModal);
  }, [props.openVideoCallModal]);

  const sendMessage = ({ contentType, content }: any = {}) => {
    return new Promise(async (resolve, reject) => {
      let txtContent = content;
      console.log("txtContent: ", txtContent);

      if (
        contentType !== "vid_call" &&
        contentType !== "end_call" &&
        !txtContent
      ) {
        return;
      }
      if (!props.currChatChannelId) {
        return;
      }

      var response, error, network;
      [response, error, network] =
          await props.chatController.postChatChannelMessage({
            content: txtContent,
            contentFile: txtContent,
            contentType: contentType ? contentType : "text",
            chatChannelId: props.currChatChannelId,
            apiToken: props.apiToken,
            divisionId: props.division_id ? props.division_id : props.division,
          });
    });
  };

  const handleCloseVideoCall = ({ sendEndMessage = true }) => {
    console.log("MainVideoCall handle close video call");

    if (sendEndMessage) {
      setTimeout(() => {
        sendMessage({
          contentType: "end_call",
          content: props.videoCallRoom, // `${CONFIG.WEB_RTC}/${props.match.params.chatChannelId}/?name=${name}`
        });
      }, 1000);
    }

    // props.setProp("openVideoCallModal", false);
    setVisible(false);
    setVideoCallStartPip(false);
    setIframeHeight(1);
    setIframeWidth(1);

    props.onCloseVideoCall();
  };

  const handleIframeResize = () => {
    setTimeout(() => {
      setIframeHeight(window.innerHeight - 100);
      setIframeWidth(window.innerWidth - 10);
      setVisible(true);
    }, 500);
  };

  const handleIframeMessage = (msg: any) => {
    
    try {
      let jsonMsg = JSON.parse(msg.data);
      console.log(msg);
      if (jsonMsg.message === "end_call") {
        
        if (jsonMsg.users_count <= 1) {
          handleCloseVideoCall({ sendEndMessage: true });
        } else {
          handleCloseVideoCall({ sendEndMessage: false });
        }
      } else if (jsonMsg.message === "start_pip") {
        setTimeout(() => {
          setIframeHeight(1);
          setIframeWidth(1);
          setVideoCallStartPip(true);
          // setVisible(false);
        }, 500);
        window.removeEventListener("resize", handleIframeResize);
      } else if (jsonMsg.message === "stop_pip") {
        window.addEventListener("resize", handleIframeResize);
        setVideoCallStartPip(false);
        handleIframeResize();
      }
    } catch (e) {
      // ignore
    }
  };

  const onLoadIfrm = () => {
    window.addEventListener("resize", handleIframeResize);

    window.removeEventListener("message", handleIframeMessage);

    window.addEventListener("message", handleIframeMessage);
    if (visible) handleIframeResize();
  };

  return (
    <div>
      {(props.openVideoCallModal) && (
        <Dimmer
          inverted
          active={visible}
          size="fullscreen"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.0)",
            width: videoCallStartPip ? "0px" : "100%",
            height: videoCallStartPip ? "0px" : "100%",
          }}
          className={{}}
        >
          <iframe
            frameBorder={0}
            onLoad={onLoadIfrm}
            scrolling="no"
            allow="microphone; camera *; display-capture *;"
            src={`${CONFIG.WEB_RTC}/${props.videoCallRoom}/?name=${props.username}&isPatient=${props.isPatient}`}
            // src={`http://localhost:3001/${props.videoCallRoom}`}
            width={videoCallStartPip ? "1px" : iframeWidth}
            height={videoCallStartPip ? "1px" : iframeHeight}
          />
        </Dimmer>
      )}
    </div>
  );
};
