import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Mob from "./tuh-lib/Mob";
import Platform from "./tuh-lib/Platform";
import MaintainScreen from "./react-lib/apps/common/MaintainScreen";
import reportWebVitals from "./reportWebVitals";
import { HashRouter, BrowserRouter } from "react-router-dom";
import CONFIG from "config/config";

const app = new URLSearchParams(window.location.search).get("app");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {CONFIG.ENABLE_MAINTAIN ? (
        <MaintainScreen />
      ) : (
        <>{app === "Mob" ? <Mob /> : <Platform />}</>
      )}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
