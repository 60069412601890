import React from "react"
import MobController from "./MobController";
import * as MobI from "./MobInterface";
import SetProp from "react-lib/frameworks/SetProp";

class Mob extends React.Component<any, MobI.State> {
  
  controller: MobController;
  
  constructor(props: any) {
    super(props);
    this.state = { ...MobI.StateInitial  }
    this.controller = new MobController(
      () => { return this.state },
      (state: MobI.State, callback: any) => { this.setState(state, callback) },
      window
    )
    this.controller.setProp = SetProp(this, "");
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} })
  }

  render = () => {
    return(
      <div>Mob</div>
    )
  }
  
}

export default Mob;