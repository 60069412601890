/* eslint-disable react/display-name */
/* eslint-disable jsx-quotes */
import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import {
  Header,
  Form,
  Input,
  Button,
  Dropdown,
  Dimmer,
  Loader,
  Label,
} from "semantic-ui-react";
import ReactTable from "react-table-6";
import { DateTextBox, ErrorMessage } from "../../common";
import Cookies from "js-cookie";
import * as CONSTANT from "../../../utils/constant";
import * as Util from "../../../utils";
import moment from "moment";
import { useInterval } from "../../../utils/hooksUtils";
import { vcMessenger } from "../../../compat/vc-websocket";
import config from "../../../../config/config";

const Dashboard = React.forwardRef((props, ref) => {
  const [reactTableColumns, setReactTableColumns] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnsDetail, setColumnsDetail] = useState([]);
  const [dashboardItems, setDashboardItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [filterDate, setFilterDate] = useState(Util.formatDate(moment()));
  const [filterClassify, setFilterClassify] = useState("");
  const [classifyChoices, setClassifyChoices] = useState([]);
  const [filterPaymentStatus, setFilterPaymentStatus] = useState("");
  const [filterDivision, setFilterDivision] = useState(null);
  const [divisionChoices, setDivisionChoices] = useState([]);
  const [hasWSReclassify, setHasWSReclassify] = React.useState(false);
  const [error, setError] = useState(null);
  const isMounted = useRef(true);

  useImperativeHandle(ref, () => ({
    // refresh: () => handleSearch()
    refresh: () => setHasWSReclassify(true),
  }));

  useEffect(() => {
    vcMessenger.reconnect({
      screens: divisionChoices.map((item) => item.value),
      token: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
    });
  }, [divisionChoices, props.apiToken, Cookies.get("apiToken"), props.division]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useInterval(() => {
    if (hasWSReclassify) {
      handleSearch({ noLoading: true });
    }
  }, 10000);

  useEffect(() => {
    handleSearch();
  }, [filterDate, filterClassify, filterPaymentStatus, filterDivision]);

  useEffect(() => {
    const getTriageLevelClassify = async () => {
      const [response] = await props.controller.getTriageLevelClassify({
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        // divisionId: props.division
      });
      if (!isMounted.current) {
        return;
      }
      if (response) {
        let options = formatTriageLevelClassifyDropdown(
          response.classify,
          "description",
          "triage_level",
          "active"
        );
        setClassifyChoices(options);
      } else {
        setClassifyChoices([]);
      }
    };

    const getDivision = async () => {
      const params: any = {};
      if (!props.denyQParamsIsTelemed) {
        params.isTelemed = true;
      }
      if (props.organization) {
        params.organization = props.organization;
      }
      const [response] = await props.controller.getDivision({
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        ...params,
      });
      if (!isMounted.current) {
        return;
      }
      if (response && response.items) {
        let options = [];
        for (let item of response.items) {
          options.push({
            key: item.id,
            text: item.name,
            value: item.id,
          });
        }
        setDivisionChoices(options);
      } else {
        setDivisionChoices([]);
      }
    };

    getTriageLevelClassify();
    getDivision();
  }, [Cookies.get("apiToken"), props.apiToken]);

  const formatTriageLevelClassifyDropdown = (
    optionsList,
    textField,
    keyField,
    activeField
  ) => {
    if (optionsList && Array.isArray(optionsList)) {
      optionsList.forEach((items) => {
        items.key = items[keyField];
        items.text = items[textField];
        items.value = items[keyField];
        if (activeField) {
          items.active = items[activeField];
        }
        delete items["description"];
        return items;
      });
      return optionsList;
    } else {
      return [];
    }
  };

  const handleFilterClassifyChange = (e, { value }) => {
    setFilterClassify(value);
  };

  const handleDateChange = (date) => {
    setFilterDate(date);
  };

  const handleSearchChange = (e, { value }) => {
    setSearchName(value);
  };

  const handleSearchKeyPress = (e) => {
    var keyCode = e.keyCode || e.charCode;
    if (keyCode === CONSTANT.KEY_CODE.ENTER) {
      return handleSearch();
    }
  };

  const handleSearch = async ({ noLoading } = { noLoading: false }) => {
    setHasWSReclassify(false);
    let search = searchName;
    setError(null);
    let date = Util.convertToADDate({ date: filterDate });
    let triageLevels = filterClassify;
    let division = filterDivision;
    let apiToken = props.apiToken ? props.apiToken : Cookies.get("apiToken");
    let paymentStatus = filterPaymentStatus;
    if (!noLoading) {
      setIsLoading(true);
    }
    let momentDate = moment(date, "DD/MM/YYYY");
    if (!momentDate.isValid() || date.includes("-")) {
      return setIsLoading(false);
    }
    const getDashboard = props.OA_DASHBOARD_API
      ? props.controller.getOfficialAccountDashboard
      : props.controller.getDashboard;

    const [res, err, network] = await getDashboard({
      apiToken,
      search,
      date: momentDate.format("YYYY-MM-DD"),
      triageLevels,
      division,
      paymentStatus,
      isTelemed: true,
      organization: props.organization
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setColumns(res.columns);
        setColumnsDetail(res.columns_detail);
        setDashboardItems(res.items);
      } else {
        setColumns([]);
        setColumnsDetail([]);
        setDashboardItems([]);
        if (typeof err === "string" && err.length > 100) {
          return setError("เกิดข้อผิดพลาด");
        } else {
          return setError(error);
        }
      }
    }
  };

  useEffect(() => {
    createReactTableColumnHeader();
  }, [columns, columnsDetail]);

  const createReactTableColumnHeader = () => {
    let newArr = [
      {
        Header: "HN ชื่อ-นามสกุล",
        accessor: "hn",
        style: { minWidth: "100px" },
        Cell: (row) => {
          return (
            <div className="hn-name-cell">
              <div className="hn">{row.original.hn}</div>
              <div>{row.original.patient_name}</div>
            </div>
          );
        },
      },
    ];
    if (config.USE_PAYMENT_STATUS_LABEL) {
      newArr.push(
        {
          Header: "สถานะการชำระค่าแพทย์",
          accessor: "payment_status_doctor_label",
          style: { minWidth: "100px" },
          Cell: (row) => {
            let notPaid = "";
            if (row.original.payment_status_doctor === "BILLED") {
              notPaid = "not-paid";
            }
            return (
              <div className={`payment-label ${notPaid}`}>
                {row.original.payment_status_doctor_label}
              </div>
            );
          },
        },
        {
          Header: "สถานะการชำระค่ายา",
          accessor: "payment_status_med_label",
          style: { minWidth: "100px" },
          Cell: (row) => {
            let notPaid = "";
            if (row.original.payment_status_med === "BILLED") {
              notPaid = "not-paid";
            }
            return (
              <div className={`payment-label ${notPaid}`}>
                {row.original.payment_status_med_label}
              </div>
            );
          },
        }
      );
    } else {
      if (!props.hidePayment) {
        newArr.push({
          Header: "สถานะการชำระเงิน",
          accessor: "payment_status_label",
          style: { minWidth: "100px" },
          Cell: (row) => {
            let notPaid = "";
            if (row.original.payment_status === "BILLED") {
              notPaid = "not-paid";
            }
            return (
              <div className={`payment-label ${notPaid}`}>
                {row.original.payment_status_label}
              </div>
            );
          },
        });
      }
    }
    columns.forEach((item) => {
      if (!columnsDetail) {
        return;
      }
      let detail = columnsDetail.find((items) => items.triage_level === item);
      if (detail) {
        newArr.push({
          Header: (data) => {
            return (
              <>
                <div>{detail.description}</div>
                <Label>
                  <h5>{detail.count}</h5>
                </Label>
              </>
            );
          },
          accessor: item.triage_level,
          style: { minWidth: "100px" },
          Cell: (row) => {
            let arr = row.original[item];
            let triageLevelDetail = {};
            let currentStatus = "";
            if (arr && arr.length > 0) {
              triageLevelDetail = arr[arr.length - 1];
            } else {
              return <></>;
            }
            if (row.original.current_triage_level === item) {
              currentStatus = "current";
            }
            if (item === "disconsultmed") {
              return (
                <Label className={`row-label ${currentStatus}`}>
                  <h4>{triageLevelDetail.date_label}</h4>
                  <div>{`${triageLevelDetail.time_label} ${triageLevelDetail.status_label}`}</div>
                </Label>
              );
            }
            return (
              <Label className={`row-label ${currentStatus}`}>
                <h4>{triageLevelDetail.time_label}</h4>
                <div>{triageLevelDetail.status_label}</div>
              </Label>
            );
          },
        });
      }
    });
    setReactTableColumns(newArr);
  };

  const handlePaymentStatusChange = (e, { value } = {}) => {
    setFilterPaymentStatus(value);
  };

  const handleDivisionChange = (e, { value } = {}) => {
    console.log(divisionChoices, "divisionChoices");
    setFilterDivision(value);
  };

  return (
    <Dimmer.Dimmable>
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
      <div className="dashboard">
        <ErrorMessage error={error} />
        <Header as="h1">TeleMed Dashboard</Header>
        <div className="filter-row">
          <b className="label">Location</b>
          <div className="division-wrapper">
            <Dropdown
              search
              fluid
              selection
              clearable
              options={divisionChoices}
              value={filterDivision}
              onChange={handleDivisionChange}
            />
          </div>
        </div>
        <div className="filter-row">
          <div
            className={`patient-search${
              props.hidePayment ? " width-half" : ""
            }`}
          >
            <b>HN/ชื่อ/นามสกุล</b>
            <Input
              onChange={handleSearchChange}
              value={searchName}
              onKeyPress={handleSearchKeyPress}
              placeholder="XX-XX-XXXXXX"
              
            />
            <Button content="ค้นหา" onClick={handleSearch} />
          </div>
          <div style={{ display: "flex" }}>
            <b>Classify</b>
            <Dropdown
              search
              selection
              options={classifyChoices}
              clearable
              onChange={handleFilterClassifyChange}
              value={filterClassify}
            />
          </div>
          {!props.hidePayment && (
            <div style={{ display: "flex" }}>
              <b>การชำระเงิน</b>
              <Dropdown
                search
                selection
                clearable
                options={[
                  {
                    key: "BILLED",
                    text: "ยังไม่ได้ชำระ",
                    value: "BILLED",
                  },
                  {
                    key: "PAID",
                    text: "ชำระแล้ว",
                    value: "PAID",
                  },
                ]}
                value={filterPaymentStatus}
                onChange={handlePaymentStatusChange}
              />
            </div>
          )}
          <div>
            <b>วันที่เข้ารับบริการ</b>
            <DateTextBox
              onChange={handleDateChange}
              value={filterDate}
              maxDate={Util.formatDate(moment())}
            />
          </div>
        </div>
        <div className="dashboard-table-wrapper">
          <ReactTable
            className="noHover"
            columns={reactTableColumns}
            showPagination={false}
            data={dashboardItems}
            // minRows={0}
            noDataText="ไม่พบข้อมูลที่ค้นหา"
            pageSize={dashboardItems.length > 10 ? dashboardItems.length : 10}
          />
        </div>
      </div>
    </Dimmer.Dimmable>
  );
});

Dashboard.propTypes = {
  apiToken: PropTypes.string,
  division: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  controller: PropTypes.object,
  // config
  OA_DASHBOARD_API: PropTypes.bool,
  denyQParamsIsTelemed: PropTypes.bool,
  hidePayment: PropTypes.bool,
  organization: PropTypes.number,
};

Dashboard.defaultProps = {
  division: null,
  apiToken: null,
  // config
  OA_DASHBOARD_API: false,
  denyQParamsIsTelemed: false,
  hidePayment: false,
  organization: null,
};

export default React.memo(Dashboard);
