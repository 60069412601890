import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "DidMount", params: {} } |
  { message: "DidUpdate", params: {} }

export type Data = 
  {
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const DidMount: Handler = async (controller, params) => {
}

export const DidUpdate: Handler = async (controller, params) => {
}