import WasmController from 'react-lib/frameworks/WasmController';
import * as MobI from './MobInterface';

export default class MobController extends 
  WasmController<MobI.State, MobI.Event, MobI.Data> {

  constructor(
    getState: () => MobI.State, 
    setState: (state: MobI.State, callback?: any) => void, 
    window: any) {
    super(getState, setState, window, MobI.DataInitial)
  }

  handleEvent = async (e: MobI.Event) => {
    console.log(e.message, e.params)
    switch (e.message) {
      // Setup
      case "DidMount":
        MobI.DidMount(this, e.params);
        return

      case "DidUpdate":
        MobI.DidUpdate(this, e.params);
        return

      default:
          let testExhausitve: never = e
    }
    console.log("-- Unhandled case")
  }
}