import React, { useMemo, useState, useEffect } from "react";
// CSS
import {
  Input,
  Button,
  Icon,
  Modal,
  Dimmer,
  Loader,
  SemanticCOLORS,
  Form,
} from "semantic-ui-react";

// Common
import PureReactTable, {
  alignCenter,
} from "react-lib/apps/common/PureReactTable";
import { ModInfo, ErrorMessage } from "react-lib/apps/common";

//  Controller
import BindHNPageController from "../REG/BindHNPageController";
import ClassifyController from "../Nurse/CardClassifyController";

// Manager
import PRXManager from "react-lib/apis/manager/PRXManager";
import UserManager from "react-lib/apis/manager/UserManager";

type CounterBindHnProps = {
  // Controller
  controller: BindHNPageController & {
    prxManager: PRXManager;
  };
  classifyController: ClassifyController & {
    userManager: UserManager;
  };
  // Data
  apiToken?: string;
  division?: string;
  history: any;
  location: any;
  match: any;
};

export type ModInfoType = {
  open?: boolean;
  error?: any;
  title?: string;
  color?: SemanticCOLORS;
  loading?: boolean;
};

const COLOR = {
  primary: "var(--primary-theme-color)",
  white_grey: "#ebeaea",
  red: "#EB5757",
  orange: "rgb(242, 153, 74)",
  green: "#27AE60",
};

const styles = {
  primary_button: {
    backgroundColor: COLOR.primary,
    color: "white",
    borderRadius: "5px",
  },
  box_filter: {
    display: "grid",
    gridTemplateColumns: "45px auto 100px",
    alignItems: "center",
    marginBottom: "15px",
    columnGap: "20px",
  },
  header_modal: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    borderBottom: `1px solid ${COLOR.white_grey}`,
  },
  flex_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  grid_content: {
    display: "grid",
    gridTemplateColumns: "150px auto",
    padding: "20px 20px 25px",
    rowGap: "20px",
    alignItems: "center",
  },
  btn_verify: {
    width: "140px",
    borderRadius: "50rem",
    color: "white",
    fontSize: ".875rem",
    padding: "9px 0",
  },
};

// Utils
const checkValidInput = (params: {
  text: string;
  prefix: string[];
  minLength?: number;
}) => {
  const { text = "", prefix = [], minLength = 0 } = params;
  if (minLength && text.length < minLength) {
    return false;
  }

  const validAll = prefix.every((value) => {
    const regex = new RegExp(`[${value}]`, "g");
    return regex.test(text);
  });

  return validAll;
};

const CounterBindHn: React.FunctionComponent<CounterBindHnProps> = (props) => {
  // Mod
  const [modType, setModType] = useState<"BIND_HN" | "CHANGE_PASSWORD" | "">(
    ""
  );
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>({});
  // Loading
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  // Data
  const [tableList, setTableList] = useState<any[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [hn, setHn] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [inputSearch, setInputSearch] = useState<any>("");

  /* ----------------------- Effect ----------------------- */
  useEffect(() => {
    if (props.division) {
      getProxyPatientHasDivision();
    }
  }, [props.division, props.apiToken]);

  /* ------------------------ Memo ------------------------ */
  const columns = useMemo(
    () => [
      {
        Header: "HN",
        accessor: "hn",
        width: 150,
        Cell: ({ original }: any = {}) => {
          return <div>{original?.hn || original?.profile_extra?.hn}</div>;
        },
      },
      {
        Header: "IH ID",
        accessor: "patient_id",
        width: 110,
      },
      {
        Header: "Username",
        accessor: "username",
        minWidth: 100,
        Cell: ({ original }: any = {}) => {
          return <div>{original?.username?.replace(/_mor\.company/g, "")}</div>;
        },
      },
      {
        Header: "ชื่อ-นามสกุล",
        accessor: "full_name",
        minWidth: 100,
        Cell: ({ original }: any = {}) => (
          <div>{`${original?.pre_name || ""}${original.first_name} ${
            original.last_name
          }`}</div>
        ),
      },
      {
        Header: "อีเมล",
        accessor: "email",
        minWidth: 120,
        Cell: ({ original }: any = {}) => {
          return (
            <div>
              {/@mor\.company$/g.test(original?.email) ? "" : original?.email}
            </div>
          );
        },
      },
      {
        Header: "ยืนยันตัวตน",
        width: 150,
        Cell: ({ original }: any = {}) => {
          return (
            <div>
              <Button
                onClick={() => handleOpenModBindHN(original)}
                style={{
                  ...styles.btn_verify,
                  backgroundColor: original?.hn ? COLOR.green : COLOR.orange,
                  ...(original?.hn ? { pointerEvents: "none" } : {}),
                }}
              >
                {original?.hn ? "ยืนยันตัวตนแล้ว" : "รอยืนยันตัวตน"}
              </Button>
            </div>
          );
        },
      },
      {
        Header: "ตั้งรหัสผ่านใหม่",
        accessor: "_new_password",
        width: 130,
        Cell: ({ original }: any) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                icon="pencil"
                style={styles.primary_button}
                size="mini"
                onClick={() => {
                  handleSelectRow(original);
                }}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const isCorrectPassword = useMemo(() => {
    let check = checkValidInput({
      text: password,
      prefix: ["0-9", "a-z", "A-Z"],
      minLength: 8,
    });

    const username = selectedPatient?.username
      ?.replace(/_mor\.company/g, "")
      .replace(/\./g, "\\.")
      .replace(/\-/g, "\\-");
    const regex = new RegExp(`[${username || ""}]+`, "g");
    const intersectUsername =
      password?.length - (password.match(regex)?.[0]?.length || 0) <= 0;

    if (intersectUsername) {
      check = false;
    }
    return check;
  }, [password, selectedPatient]);

  /* ---------------------- CALL API ---------------------- */
  const getProxyPatientHasDivision = async () => {
    setIsLoading(true);
    const [res, error] = await props.controller.prxManager.getTuhUsersList({
      apiToken: props.apiToken,
      params: {},
      // division: props.division,
    });
    setIsLoading(false);
    setTableList(res?.items || []);
  };

  const SaveBindHn = async () => {
    const [res, error] = await props.controller.prxManager.postTuhSetPatientHn({
      apiToken: props.apiToken,
      data: {
        hn,
        user: selectedPatient?.id,
      },
    });

    if (!res?.hn) {
      setOpenModInfo({ error: error || "ผูก hn ไม่สำเร็จ" });
      return;
    }

    setHn("");
    handleCloseMod();
    setOpenModInfo({ open: true, color: "green", title: "ผูก hn สำเร็จ" });
    getProxyPatientHasDivision();
  };

  const SaveChangePassword = async () => {
    const [res, error] =
      await props.classifyController.userManager.postUserSetPassword({
        apiToken: props.apiToken,
        password,
        id: selectedPatient?.id,
      });

    if (error) {
      setOpenModInfo({ open: true, error });
      return;
    }

    handleCloseMod();
    setOpenModInfo({
      open: true,
      color: "green",
      title: "เปลี่ยนรหัสผ่านสำเร็จ",
    });
    setPassword("");
    getProxyPatientHasDivision();
  };

  /* ----------------------- Handle ----------------------- */
  const handleOpenModBindHN = (original: any) => {
    if (original?.hn) {
      return;
    }
    setSelectedPatient(original);
    setModType("BIND_HN");
    setHn(original?.hn || original?.profile_extra?.hn || "");
  };

  const handleSearch = async () => {
    if (!inputSearch) {
      return;
    }
    setIsLoadingSearch(true);
    const [res, error] = await props.controller.prxManager.getTuhUsersList({
      apiToken: props.apiToken,
      params: {
        patient: inputSearch,
      },
    });
    setIsLoadingSearch(false);
    const item = res?.items?.[0];

    if (error || !item) {
      setOpenModInfo({ open: true, error: error || "ไม่พบข้อมูล" });
      return;
    }

    setSelectedPatient(item);
    setModType("BIND_HN");
    setHn(item?.hn || item?.profile_extra?.hn || "");
  };

  const handleSelectRow = (original: any) => {
    setSelectedPatient(original);
    setModType("CHANGE_PASSWORD");
  };

  /* ------------------------- Mod ------------------------ */
  const handleCloseMod = () => {
    setPassword("");
    setHn("");
    setModType("");
    setSelectedPatient(null);
    setOpenModInfo({});
  };
  const handleCloseModInfo = () => {
    setOpenModInfo({});
  };

  return (
    <div style={{ padding: "50px" }}>
      <Form style={styles.box_filter}>
        <label style={{ fontWeight: "bold" }}>Search</label>
        <Input
          value={inputSearch}
          onChange={(e, v) => setInputSearch(v.value)}
        />
        <Button
          style={styles.primary_button}
          onClick={handleSearch}
          loading={isLoadingSearch}
          disabled={!inputSearch}
        >
          Search
        </Button>
      </Form>
      <div style={{ position: "relative" }}>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        {/* Table */}
        {useMemo(
          () => (
            <PureReactTable
              data={tableList}
              columns={columns}
              showPagination={false}
              pageSize={tableList.length > 10 ? tableList.length : 10}
              manual
              style={{ height: "77vh" }}
            />
          ),
          [tableList, columns]
        )}
      </div>

      {/* Bind HN */}
      <Modal
        open={!!modType}
        size="small"
        style={{ borderRadius: "5px" }}
        onClose={handleCloseMod}
        closeOnDimmerClick
      >
        <div style={styles.header_modal}>
          <label style={{ fontWeight: "bold", fontSize: "1.25rem" }}>
            {modType === "BIND_HN"
              ? "กรุณาตรวจสอบข้อมูลและกรอก HN"
              : "ตั้งรหัสผ่านใหม่"}
          </label>
          <Icon
            style={{ cursor: "pointer" }}
            name="close"
            onClick={handleCloseMod}
          />
        </div>
        <Dimmer active={openModInfo.loading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        {openModInfo.error && (
          <div style={{ padding: "20px 20px 0px" }}>
            <ErrorMessage error={openModInfo.error} />
          </div>
        )}
        <div style={styles.grid_content}>
          <label style={{ fontWeight: "bold" }}>IH ID:</label>
          <label style={{ fontWeight: "bold" }}>
            {selectedPatient?.patient_id}
          </label>
          {/*  */}
          <label style={{ fontWeight: "bold" }}>ชื่อ-นามสกุล:</label>
          <label style={{ fontWeight: "bold" }}>
            {`${selectedPatient?.pre_name || ""}${
              selectedPatient?.first_name
            } ${selectedPatient?.last_name}`}
          </label>
          {/*  */}
          <label style={{ fontWeight: "bold" }}>E-mail:</label>
          <label style={{ fontWeight: "bold" }}>
            {/@mor\.company$/g.test(selectedPatient?.email)
              ? ""
              : selectedPatient?.email}
          </label>
          {modType === "BIND_HN" && (
            <>
              <label style={{ fontWeight: "bold" }}>HN:</label>
              <Input
                style={{ width: "50%" }}
                value={hn}
                onChange={(e, v) => setHn(v.value)}
              />
            </>
          )}
        </div>

        {modType === "CHANGE_PASSWORD" && (
          <div
            style={{
              borderTop: `1px solid ${COLOR.white_grey}`,
              padding: "20px",
            }}
          >
            <div style={{ color: "red", fontSize: "1.125rem" }}>
              กรุณาระบุเป็นตัวอักษรภาษาอังกฤษ
              ตัวใหญ่ตัวเล็กและตัวเลขความยาวไม่น้อยกว่า 8 ตัวอักษร
            </div>
            <div style={{ ...styles.grid_content, padding: "25px 0 10px" }}>
              <label style={{ fontWeight: "bold" }}>Password</label>
              <Input
                style={{ width: "50%" }}
                value={password}
                type="password"
                onChange={(e, v) => setPassword(v.value)}
              />
            </div>
          </div>
        )}

        {modType === "CHANGE_PASSWORD" && (
          <div style={{ ...styles.flex_center, marginBottom: "25px" }}>
            <Button
              style={{ ...styles.primary_button, padding: "0.78em 3em" }}
              onClick={SaveChangePassword}
              disabled={modType === "CHANGE_PASSWORD" && !isCorrectPassword}
            >
              บันทึก
            </Button>
          </div>
        )}
        {modType === "BIND_HN" && (
          <div style={{ ...styles.flex_center, marginBottom: "25px" }}>
            <Button
              style={{
                ...styles.primary_button,
                background: COLOR.orange,
                padding: "0.78em 3em",
              }}
              onClick={handleCloseMod}
            >
              ปิด
            </Button>
            <Button
              style={{ ...styles.primary_button, padding: "0.78em 3em" }}
              onClick={() => {
                if (modType === "BIND_HN") {
                  SaveBindHn();
                } else {
                  SaveChangePassword();
                }
              }}
              disabled={modType === "BIND_HN" && hn?.length !== 7}
            >
              ยืนยันตัวตน
            </Button>
          </div>
        )}
      </Modal>

      <ModInfo
        open={openModInfo.open}
        titleColor={openModInfo.color || "red"}
        titleName={openModInfo.title}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        {openModInfo.error === "string" ? (
          <div>{openModInfo.error}</div>
        ) : (
          <ErrorMessage error={openModInfo.error} />
        )}
      </ModInfo>
    </div>
  );
};

CounterBindHn.defaultProps = {} as CounterBindHnProps;

const MOCK_DATA = [
  {
    hn: "123-01-22563",
    id: "20",
    username: "User12388",
    full_name: "Pech Pecth",
    email: "saengtawan@hotmail.com",
  },
];

export default CounterBindHn;
